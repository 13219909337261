@import "../../../sass/partials/colors";
@import "../../../sass/partials/images";
@import "../../../sass/partials/base";

.inApp {
    //width: 5%;
    //height: 50%;
    //padding-left: 25px;
    width: 30px;
    margin-right: 10px;
    margin-top: 9px;
    .in-app-img {
        @include create-image($inAppIcon, 10px, 35px, 20px, 23px 23px, 4px 15px, "");
    }
    .in-app-img:hover {
        opacity: 0.6;
    }
    .notificationLength {
        position: fixed !important;
    }
    .notification-count-div {
        font-size: 10px;
        font-weight: bold;
        margin-left: 15px;
        margin-top: 5px;
        padding: {
            left: 5px;
            top: 2px;
            right: 5px;
            bottom: 4px;
        }
        position: absolute;
        color: white;
        border-radius: 8px;
        background-color: red;
        height: 10px;
    }
}
.inApp-mobile {
    width: 30px;
    height: 30px;
    .notification-icon {
        @include create-image($inAppIcon, 30px, 14px, 13px, 24px 24px, 0px 3px, "");
    }
    .inotification-icon:hover {
        opacity: 0.6;
    }
}
