@import "../../sass/partials/base";
@import "../../sass/partials/images";
@import "../../sass/partials/colors";
.calender-container {
    bricks-tooltip::part(content) {
        display: block !important;
    }
    bricks-tooltip::part(base) {
        width: -webkit-max-content;
        width: max-content;
    }
    padding: 15px;

    .calender-header {
        font: {
            family: SFProDisplay-Semibold;
            size: 32px;
            weight: 600;
        }
        letter-spacing: 0.19px;
        line-height: 38.4px;
        color: $blackRussian;
    }

    .calender-legend {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: {
            top: 20px;
            bottom: 14px;
        }

        .legend-shapes,
        .legend-shortcuts {
            display: flex;

            .calender-legend-item {
                align-items: center;
                margin: {
                    right: 20px;
                    bottom: 10px;
                }

                .legend-shortcut-text,
                .legend-shape-text {
                    font: {
                        family: SFProText-Regular;
                        size: 15px;
                        weight: 400;
                    }
                    text-align: center;
                    margin-right: 8px;
                    letter-spacing: -0.18px;
                }
                .legend-shortcut-text {
                    color: $smokeGrey;
                }
                .legend-icon-out-of-scope,
                .legend-icon-excused,
                .legend-icon-unexcused{
                    margin-left: 40%;
                }
                .legend-icon-no-event {
                    background-color: #7cccaf;
                    border-radius: 1px;
                    height: 0.56em;
                    margin-left: 40% !important;
                    margin: 0.4em 1px 0.24em 2px;
                    transform: rotate(45deg);
                    width: 0.56em;
                }
            }
        }
    }

    .calender-table {
        max-height: 500px;
        overflow-y: auto;

        .attendance-table {
            width: 100%;
            border-collapse: collapse;

            thead {
                position: sticky;
                top: 0;
                background-color: $white;
                z-index: 1;
                th {
                    font: {
                        // family: SFProText-Semibold;
                        size: 14px;
                        weight: 600;
                    }
                    color: $blackRussian;
                    letter-spacing: -0.18px;
                    text-align: center;
                }
            }

            tr:nth-child(odd) {
                background-color: $solitude;
            }

            tr:nth-child(even) {
                background-color: $white;
            }

            .calendar-items {
                display: flex;
                justify-content: center;
                align-items: center;
                gap:3px;
                .calendar-item {
                    display: flex;
                    align-items: center;
                    gap: 1px;
                    .calendar-item-shape {
                        cursor: pointer;
                    }
                    .calendar-item-shape:hover {
                        opacity: 0.6;
                    }
                    .calendar-item-text {
                        font: {
                            family: SFProText-Regular;
                            size: 15px;
                            weight: 400;
                        }
                        color: $smokeGrey;
                        letter-spacing: -0.18px;
                    }
                }
            }

            .occurrence-balance-td {
                position: relative;
                .occurrence-balance-div {
                    display: inline-flex;
                    display: -webkit-inline-flex;
                    .above-threshold-warning-span {
                        @include create-image($cautionIcon, 35px, 23px, 0px, 16px 13px, 12px 2px, "");
                    }
                    .above-threshold-warning-val-span {
                        font-size: 14px;
                        color: $carrotOrange;
                    }
                    .above-threshold-warning-span:hover {
                        opacity: 0.6;
                    }
                }
            }
            .tooltip-contents {
                .event-tooltip-div {
                    background-color: $aquaHaze;
                    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.3);
                    width: -webkit-max-content;
                    width: max-content;
                    font: {
                        size: 14px;
                        weight: 500;
                    }
                    text-align: left;
                    .event-tooltip-date {
                        padding: {
                            top: 5px;
                        }
                        .event-tooltip-calendar-icon {
                            @include create-image($date, 23px, 23px, 0px, 15px 15px, 0px 1px, "");
                        }
                    }
                    .event-tooltip-clocktime {
                        padding: {
                            top: 5px;
                        }
                        .event-tooltip-clock-icon {
                            @include create-image($clock, 23px, 23px, 0px, 15px 15px, 0px 1px, "");
                        }
                        .event-tooltip-shift-icon {
                            @include create-image($shiftTime, 23px, 0px, 0px, 19px 19px, 0px -1px, "");
                        }
                        .event-tooltip-impact-icon {
                            @include create-image($impactTime, 23px, 0px, 0px, 18px 17px, 1px 0px, "");
                        }
                        .event-padding {
                            padding-left: 25px;
                        }
                    }
                    .separator {
                        padding-top: 5px;
                        border-bottom: 1px solid $gainsboro;
                    }
                }
                .occurrence-balance-tooltip {
                    background-color: $smokeWhite;
                    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.3);
                    font: {
                        size: 14px;
                    }
                    text-align: left;
                }
            }
        }

        .attendance-table th,
        .attendance-table td {
            border: 1px solid $white;
            border-right: 1px solid $gainsboro;

            text-align: center;
            padding: 8px;
        }

        .attendance-table th {
            height: 50px;
            background-color: $white;
            border-bottom: 1px solid $black;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            font-family: SFProText-Regular;
            font-size: 14px;
            font-weight: 600;
        }
        .qtr-week-td {
            .week-div {
                font: {
                    font-family: SFProText-Regular;
                    size: 14px;
                    weight: 600;
                }
            }
            .date-div {
                font: {
                    family: SFProText-Regular;
                    size: 12px;
                    weight: 400;
                }
                color: $monsoon;
                letter-spacing: -0.18px;
                text-align: center;
            }
        }
    }
    .out-of-scope {
        color: $nobel;
    }

    .excused {
        color: $amethystSmoke;
    }

    .unexcused {
        color: $lightCoral;
    }

    .no-event {
        color: $paleTeal;
    }

    .legend-icon-out-of-scope {
        background-color: $nobel;
        border-radius: 7.5px;
        height: 12px;
        margin: 2px 3px 2px 2px;
        width: 12px;
    }

    .legend-icon-excused {
        background-color: $amethystSmoke;
        height: 12px;
        margin: 0.3em 3px 2px 2px;
        width: 12px;
        border-radius: 3px;
    }

    .legend-icon-unexcused {
        border-bottom: 12px solid $lightCoral;
        border-left: 6px solid #0000;
        border-right: 6px solid #0000;
        height: 0;
        margin: 5px 3px 2px 2px;
        width: 0;
    }

    .legend-icon-no-event {
        background-color: $paleTeal;
        height: 0.55em;
        margin: 6px 3px 2px 2px;
        width: 0.55em;
        border-radius: 1px;
        transform: rotate(45deg);
    }
}