@import "./../../sass/partials/images";
@import "./../../sass/partials/colors";
@import "./../../sass/partials/base";

#header {
    position: fixed;
    width: 100%;
    top: 0px;
    height: 70px;
    border-bottom: solid 1px #d2d2d7;
    clear: both;
    background: rgba(0, 0, 0, 0.8);
    min-width: 200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 999999;

    .header-label {
        display: flex;
        align-items: center;
        padding-left: 20px;
        font-size: 1.2vw;
        color: #a1a1a6;
        letter-spacing: 0;
        line-height: 15.96px;
        font-weight: 400;
        align-content: center;
         min-width: 11vw;
    }

    .header-icon {
        //min-width: 250px;
        display: flex;
        justify-content: end;
        margin-right:10px;
        // gap: 2px;

        .language-div {
            margin-top: 18px;
        }
        .notification-container-div {
            margin-top: 8px;
            margin-left: 15px;
            .in-app-notification-icon {
                @include create-image($inAppIcon, 35px, 35px, 20px, 20px 20px, 4px 17px, "");
            }
        }
        .user-guide-container {
            //margin-right: 10px;
            margin-top: 8px;
            .user-guide-icon-div {
                width: 30px;
                

                .user-guide-info-icon {
                    @include create-image($userGuideIcon, 5px, 35px, 20px, 20px 20px, 4px 17px, "");
                }
                .user-guide-info-icon:hover {
                    opacity: 0.6;
                }
            }
        }
        .signout-div {
            display: grid;
            place-items: center;
        }
        
    }

    .vertical-center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width:100%;
        padding: {
            left: 10px;
            right: 10px;
        }
        .timeframe {
            display: flex;

            .period {
                margin-top: 20px;
            }
            .geo,
            .timeperiod,
            .timeslice,
            .absencetype {
                font-size: 0.8vw;
                color: #a1a1a6;
                letter-spacing: 0;
                line-height: 15.96px;
                font-weight: 400;
            }

            .geo-value,
            .timeperiod-value,
            .timeslice-value,
            .absencetype-value {
                font-size: 0.9vw;
                color: #f5f5f7;
                letter-spacing: -0.18px;
                line-height: 21px;
                font-weight: 400;
            }
        }

        .separator-div {
            height: 25px;
            border-right: solid 1px #a1a1a6;
            margin-top: 4%;
            margin-left: 20px;
            margin-right: 20px;
        }

        .expand-collapse-expand {
            content: "";
            right: 2%;
            background: $headerExpand no-repeat;
            padding-left: 28px;
            padding-bottom: 8px;
            background-size: 20px 15px;
            background-position: 0px -5px;
            border: 9px solid transparent;
        }

        .expand-collapse-collapse {
            content: "";
            right: 2%;
            background: $headerCollapse no-repeat;
            padding-left: 28px;
            padding-bottom: 8px;
            background-size: 20px 15px;
            background-position: 0px -5px;
            border: 9px solid transparent;
        }
    }

    .vertical-center-highlight {
        // background: rgb(255, 255, 255, 0.1);
        border-radius: 16px 16px 0 0;
    }
    .filter-info-popup {
        position: fixed;
        z-index: 9999;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgba(0, 0, 0, 0.4);
        top: 70px;
        margin-top: 1px;
        opacity: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        #FilterContainer {
            position: fixed;
            width: clamp(90%, 75vw, 75%);
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            background: $white;
            border: 1px solid #f5f5f7;
            box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2);
            border-radius: 0 0 15px 15px;

            #inner-FilterContainer {
                .filterbody {
                    display: flex;
                    padding: 20px 30px 20px 20px;
                    justify-content: space-between;

                    .section {
                        flex: 1;
                        background-color: $white;
                        //padding: 15px;
                        border-left: 1px solid #ccc; /* Vertical separator */
                        // --bricks-radio-button-label-font-size: 0.7vw;
                        // --bricks-radio-button-label-font-weight: 500;
                        //--bricks-radio-button-size: 1.2rem;
                        @media (max-width: 1024px) {
                            //--bricks-radio-button-size: 0.6rem;
                        }

                        &:first-child {
                            border-left: none; /* Remove border from the first section */
                        }
                        .radio-button-group{
                            margin-top:-15px;
                        }
                        bricks-radio-button-group::part(radio-group-label) {
                            font-size: 16px;
                            color: $blackRussian;
                            font-weight: 500;
                        }
                        bricks-radio-button::part(label) {
                            font-size: 16px;
                            color: $blackRussian;
                            font-weight: 400;
                            
                            //margin-top: 5px;
                        }
                        .event-header-label {
                            font-size: 16px;
                            color: $blackRussian;
                            font-weight: 600;
                            padding-bottom: 23px;
                        }

                        .date-picker {
                            padding-top: 10px;
                            position: absolute;
                        }
                        .section-label{
                            font-size: 16px;
                            color: $blackRussian;
                            font-weight: 600;
                        }
                    }

                    .time-period-section {
                        padding-top: 15px;
                        padding-bottom: 15px;
                        padding-left: clamp(20px, 2vw, 50px);
                        //padding-right: 30px;
                    }
                    .excusal-status-section {
                        padding-top: 15px;
                        padding-bottom: 15px;
                        padding-left: clamp(20px, 2vw, 50px);
                        //padding-right: 20px;
                        hr {
                            margin-right: 60px;
                            margin-block-start: 1rem;
                        }
                    }
                    .store-section {
                        flex-grow: 1.7;
                        padding-top: 20px;
                        padding-bottom: 15px;
                        padding-left: 20px;
                        padding-right: 30px;
                    }
                    .attendance-event-section {
                        min-width: 180px;
                        padding-top: 15px;
                        padding-bottom: 15px;
                        padding-left: clamp(20px, 2vw, 50px);
                        
                    }

                    // div {
                    //     width: 100%;
                    // }

                    .AttendanceEvent {
                        hr {
                            margin-right: 60px;
                        }
                    }
                    .category-checkbox {
                        // padding:0.3rem 0.1rem;
                    }
                    .sub-category-checkbox {
                        margin-left: 25px;
                    }
                    .category-label {
                        font-size: 16px;
                        font-weight: 380;
                        padding-left: 10px;
                    }
                    .group-flex {
                        display: flex;
                        padding-top: 5px;
                        padding-bottom: 5px;
                    }
                    .custom-checkbox {
                        //margin-top: 5px;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        width: 20px;
                        height: 20px;
                        background-color: $white;
                        border: 1px solid #ccc;
                        border-radius: 4px;
                        cursor: pointer;
                        position: relative;
                        transition: background-color 200 ease-in-out;
                    }

                    .custom-checkbox.checked {
                        background-color: rgb(22, 113, 234);
                        border-color: rgb(22, 113, 234);
                    }

                    .custom-checkbox.checked::after {
                        content: "\2713\0020";
                        font-size: 1rem;
                        color: $white;
                    }
                    .store-container {
                        width: 100%;

                        .store-label {
                            font-size: 16px;
                            color: $blackRussian;
                            font-weight: 600;
                        }
                        .store-content {
                            border: 1px solid #e0e0e0;
                            border-radius: 8px;
                            padding: 10px;
                            width: 90%;
                            margin-top: 25px;
                            //--bricks-text-field-width:100% !important;
                            .search-result {
                                max-height: 250px;
                                min-height: 250px;
                                overflow-x: hidden;
                                overflow-y: auto;
                                padding-top: 10px;
                                .custom-radio {
                                    padding: 6px;
                                }
                            }
                        }
                    }
                }

                .filter-botton-container {
                    display: flex;
                    justify-content: flex-end;
                    padding-right: 3vw;
                    padding-bottom: 15px;
                    .cancel {
                        margin: 10px 5px;
                    }

                    .submit {
                        margin: 10px 5px;
                    }
                }
            }
        }
    }
}