@import "./../../../sass/partials/images";
@import "./../../../sass/partials/colors";
@import "./../../../sass/partials/base";

.signout-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: -webkit-fill-available;
    width: -webkit-fill-available;
    .signout-logo-container {
        display: grid;
        .signout-apple-logo-div {
            .signout-apple-logo-span {
                @include create-image($apple, 100%, 100%, "", 50% 50%, "", "");
            }
        }
        .signout-content-div {
            padding-top: 60%;
            .signout-msg {
                padding-bottom: 10px;
                font: {
                    size: 15px;
                    weight: 700;
                }
            }
        }
    }
}