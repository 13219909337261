@import "../../sass/partials/colors";

#container {
    margin: {
        left: 20px;
        right: 20px;
    }

    @media (max-width: 768px) {
        &.corporate {
            margin-top: 209px;
        }

        &.non-corporate {
            margin-top: 100px;
        }
    }

    .summary {
        display: flex;
        justify-content: space-between;
        padding: 20px 0px;

        @media (max-width: 768px) {
            flex-direction: column;
        }

        .summary-label {
            font-family: SFProDisplay-Semibold;
            font-size: 32px;
            font-weight: 600;
            letter-spacing: 0.19px;
            line-height: 38.4px;
            color: $blackRussian;

            @media (max-width: 768px) {
                font-size: 1.7rem;
            }
        }

        .refresh-label {
            font-size: 12px;
        }

        .radio-content {
            display: flex;
            flex-direction: column;

            .team-description {
                font-family: SFHello-Regular;
                font-size: 12px;
                color: #6e6e73;
                padding-left: 5px;
            }

            .title {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        .radio-content:hover span {
            color: $white;
        }
        .selector-scroll > bricks-selector {
            --bricks-selector-box-menu-max-height: 200px;
        }
    }
    .summary-card-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        /* Allow wrapping for smaller screens */

        .row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .card {
            position: relative;
            margin-left: 10px;
            margin-right: 10px;
            border-radius: 12px;
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
            width: calc(33% - 20px);
            /* 3 cards per row with margin */

            /* Mobile specific adjustments */
            @media (max-width: 768px) {
                width: 100%;
                margin-left: 0;
                margin-right: 0;
                margin-bottom: 20px;
            }
        }

        .card-hour,
        .card-shift {
            flex: 1;
        }

        .card-occurance {
            width: 10%;

            .card-col-header {
                font-size: 0.8vw;
                color: #6e6e73;
                letter-spacing: -0.1px;
                text-align: center;
                font-weight: 400;
                height: 3.5rem;
                line-height: 3.5;
            }
        }

        .card-header {
            padding: 0.6rem;
            background: #e8e8ed;
            border-radius: 11px 11px 0 0;
            text-align: center;
            font-size: 1.2vw;
            font-weight: 600;
            letter-spacing: 0.27px;
            color: $blackRussian;
        }

        .card-content {
            padding: 0.6vw;
            text-align: center;
            font-size: 1rem;
            background-color: white;
            color: black;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;

            .impact-section {
                text-align: center;
                //margin: 0 20px;

                .impact-values {
                    display: flex;
                    justify-content: space-between;
                }

                .card-col-header {
                    font-size: clamp(12px, 0.9vw, 18px);
                    color: #6e6e73;
                    letter-spacing: -0.1px;
                    text-align: center;
                    font-weight: 400;
                    height: 3.5rem;
                    // line-height: 3.5;
                }

                .impact-value {
                    margin: 0 10px;
                }

                .percentage {
                    font-size: 1.5vw;
                    font-weight: bold;
                    color: $black;
                    margin: 0;
                }

                .label {
                    font-size: 0.8vw;
                    color: #6e6e6e;
                    margin: 0;
                }
            }
        }

        .card-col {
            width: 100%;

            .card-col-header {
                font-size: clamp(12px, 0.9vw, 18px);
                color: #6e6e73;
                letter-spacing: -0.1px;
                text-align: center;
                font-weight: 400;
            }
            .card-col-header-occurance {
                font-size: clamp(12px, 0.9vw, 18px);
                color: #6e6e73;
                letter-spacing: -0.1px;
                text-align: center;
                font-weight: 400;
                span {
                    font-size: 1.5vw;
                    color: $blackRussian;
                    letter-spacing: 0.21px;
                    text-align: center;
                    font-weight: 600;
                    word-wrap: nowrap;
                    line-height: 1;
                }
            }

            span {
                font-size: 1.5vw;
                color: $blackRussian;
                letter-spacing: 0.21px;
                text-align: center;
                font-weight: 600;
                word-wrap: nowrap;
                line-height: 2;
            }
        }
    }

    /* Mobile view: stack cards vertically */
    @media (max-width: 768px) {
        .summary-card-container {
            flex-direction: column;

            /* Stack cards vertically */
            .card-header {
                font-size: 1rem;
            }

            .card-col {
                width: 100%;

                .card-col-header {
                    font-size: 14px;
                    height: 2rem;
                }

                span {
                    font-size: 1.2rem;
                }
            }

            .card-content {
                padding-bottom: 10px;
                text-align: center;
                font-size: 1rem;
                background-color: white;
                color: black;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;

                .impact-section {
                    text-align: center;
                    margin: 0;

                    .impact-values {
                        display: flex;
                        justify-content: space-between;
                    }

                    .card-col-header {
                        font-size: 14px;
                        height: 2.5rem;
                    }

                    .impact-value {
                        margin: 0 10px;
                    }

                    .percentage {
                        font-size: 1.2rem;
                        font-weight: bold;
                        color: $black;
                        margin: 0;
                    }

                    .label {
                        font-size: 0.8rem;
                        color: #6e6e6e;
                        margin: 0;
                    }
                }
            }

            .card-occurance {
                width: 100%;

                .card-col-header {
                    font-size: 14px;
                    line-height: 2rem;
                }

                span {
                    font-size: 1.2rem;
                }
            }
        }
    }
}
.non-corporate.ipad {
    margin-top: 100px;
}
.corporate.ipad {
    margin-top: 210px;
}
.desktop {
    margin-top: 70px;
}
