@import "./../../../sass/partials/images";
@import "./../../../sass/partials/base";

.signout-div {
    .signout-icon-header {
        @include create-image($signoutIcon, "", "", 20px, 20px 20px, 0px 0px, "");
    }
    .signout-icon-menu {
        @include create-image($signoutIcon, 20px, 5px, 20px, 25px 24px, 10px -1px, "");
    }
    .signout-icon:hover {
        opacity: 0.6;
    }
}